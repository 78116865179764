<template>
    <ui-async :guards="channels?.data">
        <div v-if="channels?.data.length" class="w-full mb-4 py-4 px-5 space-y-8">
            <channels-bar :sources="channels.data" caption="Platforms" obj_key="type"/>
            <channels-bar :sources="reports.data" caption="Languages" obj_key="language"/>
        </div>

        <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full">
<!--            <div class="pb-2">-->
<!--                <div class="float-right">-->
<!--                    Order by:-->
<!--                    <span class="text-gerulata-green-100 cursor-pointer">-->
<!--                        Alphabetically-->
<!--                        <ui-icon name="chevron-down"></ui-icon>-->
<!--                    </span>-->
<!--                </div>-->
<!--                <div class="font-semibold text-gray-100">Channels</div>-->
<!--            </div>-->

            <ui-async :guards="channels?.data">
                <channels-list :channels="sortedChannels()"></channels-list>
            </ui-async>
        </div>
    </ui-async>
</template>


<script>
import ChannelsBar from '../partials/channels-bar'
import ChannelsList from '../partials/channels-list'

import useDetailsStore from "@/stores/overview/details"

import { mapState } from 'pinia'

export default {
    components: {
        ChannelsBar, ChannelsList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'channels', 'reports' ])
    },

    methods: {
        sortedChannels() {
            if (this.channels.data) {
                return this.channels.data.sort((a, b) => a.name.localeCompare(b.name))
            }

            return null
        }
    }
}
</script>
