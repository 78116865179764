<template>
    <div>
        <div class="py-3 px-16 border-b border-gray-500 w-full" v-if="checkData">
            <div class="flex justify-between">
                <div v-for="(metric, index) in Object.keys(metricsData)" :key="index" class="flex flex-col border-gray-900/10">
                    <div class="text-sm leading-6 text-gray-100 capitalize">{{ metric }}</div>
                    <div class="order-first text-3xl font-semibold tracking-tight text-gray-100 text-center">{{ metrics.data[metric] }}</div>
                </div>
            </div>
        </div>

        <ui-async :guards="reports.data">
            <div class="px-5 pt-5">
                <content-list :reports="reports?.data"></content-list>
            </div>
        </ui-async>
    </div>
</template>

<script>


import useDetailsStore from "@/stores/overview/details"

import { mapState } from 'pinia'
import ContentList from "@/components/overview/details/partials/content-list.vue";

export default {
    components: {
        ContentList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'reports', 'metrics' ]),

        checkData() {
            if (this.metrics.data) {
                let sum = 0
                Object.keys(this.metrics.data).forEach(key => { sum += this.metrics.data[key] })
                return sum > 0
            }

            return false
        },

        metricsData() {
            Object.keys(this.metrics.data).forEach(key => {
                if (this.metrics.data[key] <= 0) {
                    delete this.metrics.data[key]
                }
            })

            return this.metrics.data
        }
    }
}
</script>