<template>
    <div class="px-5 py-4">
        We found <span class="font-medium">{{events.data?.length}}</span> matching events in this location.
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500">
        <h2 class="font-medium text-lg mb-4">
            Events
        </h2>

        <ui-async :guards="events.data">
            <events-list :events="events.data.slice(0, 3)"></events-list>
        </ui-async>
        
        <div class="mt-5">
            <a @click.prevent="shownTab = 'events'" href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300">
                Show all <ui-icon name="chevron-right"></ui-icon>
            </a>
        </div>
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500">
        <h2 class="font-medium text-lg mb-4">
            Content
        </h2>

        <ui-async :guards="reports.data">
            <content-list :reports="reports?.data?.slice(0, 3)"></content-list>
        </ui-async>

        <div class="mt-5">
            <a @click.prevent="shownTab = 'stream'" href="#" class="text-gerulata-green-100 hover:text-gerulata-green-300">
                Show all <ui-icon name="chevron-right"></ui-icon>
            </a>
        </div>
    </div>
</template>

<script>
import EventsList from '../partials/events-list'
import ContentList from '../partials/content-list'
import useDetailsStore from '@/stores/overview/details'

import { mapState, mapWritableState } from 'pinia'

export default {
    components: {
        EventsList, ContentList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'events', 'reports' ]),
        ...mapWritableState(useDetailsStore, [ 'shownTab' ])
    },
}
</script>