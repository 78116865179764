const options = [
    { name: 'Saudi Arabia', id: 'ar', icon: 'flag-sa' },
    { name: 'Belarus', id: 'be', icon: 'flag-by' },
    { name: 'Bulgaria', id: 'bg', icon: 'flag-bg' },
    { name: 'China', id: 'zh', icon: 'flag-cn' },
    { name: 'Czechia', id: 'cs', icon: 'flag-cz' },
    { name: 'Denmark', id: 'da', icon: 'flag-dk' },
    { name: 'Netherlands', id: 'nl', icon: 'flag-nl' },
    { name: 'United Kingdom', id: 'en', icon: 'flag-uk' },
    { name: 'Estonia', id: 'et', icon: 'flag-ee' },
    { name: 'Finland', id: 'fi', icon: 'flag-fi' },
    { name: 'France', id: 'fr', icon: 'flag-fr' },
    { name: 'Germany', id: 'de', icon: 'flag-de' },
    { name: 'Greece', id: 'el', icon: 'flag-gr' },
    { name: 'Israel', id: 'he', icon: 'flag-il' },
    { name: 'India', id: 'hi', icon: 'flag-in' },
    { name: 'Hungary', id: 'hu', icon: 'flag-hu' },
    { name: 'Ireland', id: 'ga', icon: 'flag-ie' },
    { name: 'Italy', id: 'it', icon: 'flag-it' },
    { name: 'South Korea', id: 'ko', icon: 'flag-kr' },
    { name: 'Latvia', id: 'lv', icon: 'flag-lv' },
    { name: 'Japan', id: 'ja', icon: 'flag-jp' },
    { name: 'Lithuania', id: 'lt', icon: 'flag-lt' },
    { name: 'Norway', id: 'no', icon: 'flag-no' },
    { name: 'Poland', id: 'pl', icon: 'flag-pl' },
    { name: 'Portugal', id: 'pt', icon: 'flag-pt' },
    { name: 'Romania', id: 'ro', icon: 'flag-ro' },
    { name: 'Russia', id: 'ru', icon: 'flag-ru' },
    { name: 'Serbia', id: 'sr', icon: 'flag-rs' },
    { name: 'Slovakia', id: 'sk', icon: 'flag-sk' },
    { name: 'Slovenia', id: 'sl', icon: 'flag-si' },
    { name: 'Spain', id: 'es', icon: 'flag-es' },
    { name: 'Sweden', id: 'sv', icon: 'flag-se' },
    { name: 'Turkey', id: 'tr', icon: 'flag-tr' },
    { name: 'Ukraine', id: 'uk', icon: 'flag-ua' },
    {},
    { name: 'South Africa', id: 'af', icon: '' },
    { name: 'Albania', id: 'sq', icon: '' },
    { name: 'Armenia', id: 'hy', icon: '' },
    { name: 'Azerbaijan', id: 'az', icon: '' },
    { name: 'Russia', id: 'ba', icon: '' },
    { name: 'Basque Country', id: 'eu', icon: '' },
    { name: 'Bosnia', id: 'bs', icon: '' },
    { name: 'France', id: 'br', icon: '' },
    { name: 'Myanmar', id: 'my', icon: '' },
    { name: 'Spain', id: 'ca', icon: '' },
    { name: 'Cambodia', id: 'km', icon: '' },
    { name: 'Russia', id: 'cv', icon: '' },
    { name: 'France', id: 'co', icon: '' },
    { name: 'Croatia', id: 'hr', icon: '' },
    { name: 'Global', id: 'eo', icon: '' },
    { name: 'Spain', id: 'gl', icon: '' },
    { name: 'Uganda', id: 'lg', icon: '' },
    { name: 'Georgia', id: 'ka', icon: '' },
    { name: 'India', id: 'gu', icon: '' },
    { name: 'Indonesia', id: 'id', icon: '' },
    { name: 'Iceland', id: 'is', icon: '' },
    { name: 'Global', id: 'ia', icon: '' },
    { name: 'Global', id: 'ie', icon: '' },
    { name: 'Kazakhstan', id: 'kk', icon: '' },
    { name: 'Kyrgyzstan', id: 'ky', icon: '' },
    { name: 'Kurdistan', id: 'ku', icon: '' },
    { name: 'Rome', id: 'la', icon: '' },
];

export default options
