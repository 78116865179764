<template>
    <div class="w-full mb-2.5">
        <div class="font-semibold text-gray-100 mb-2">{{ caption }}</div>
        <div class="w-full block clear-both">
            <div
                class="color-bar float-left h-1"
                v-for="(item, index) in Object.keys(processed.data)"
                :key="index"
                :class="`color${index + 1} color-${item}`"
                :style="{
                    width: `${processed.data[item] / processed.sum * 100}%`,
                    backgroundColor: platforms[item]?.color || ''
                }"
            ></div>
        </div>

        <div class="clear-both mt-4">
            <div v-for="(item, index) in Object.keys(processed.data)" class="text-sm text-gray-300 inline-block ml-1" :key="index">
                {{ processed.data[item] }}
                <ui-icon :name="icon(item)"  v-if="icon(item)" class="mr-2 ml-1 mt-1"></ui-icon>
                <span class="capitalize">{{ title(item) }}</span>
                <span v-if="index + 1 < Object.keys(processed.data).length" class="font-bold mx-2">•</span>
            </div>
        </div>
    </div>
</template>


<script>

import { mapState } from "pinia"
import useDetailsStore from "@/stores/overview/details"
import languageFilterOptions from "@/stores/me/languages"

export default {
    props: [ 'sources', 'caption', 'obj_key' ],

    computed: {
        ...mapState(useDetailsStore, [ 'platforms' ]),

        processed: function () {
            const data = this.sources || []
            const result = { data: {}, sum: data.length }

            data.forEach((item) => {
                if (result.data[item[this.obj_key]]) {
                    result.data[item[this.obj_key]]++;
                } else {
                    result.data[item[this.obj_key]] = 1;
                }
            });

            return result;
        }
    },

    methods: {
        title: function(item) {
            if (item == '00') {
                return "Unknown"
            }

            if (this.platforms[item]) {
                return this.platforms[item].label
            }

            const lang = languageFilterOptions.find(o => o.id == item)
            if (lang) {
                return lang.name
            }

            return item
        },

        icon: function(item) {
            return languageFilterOptions.find(o => o.id === item)?.icon || null
        }
    }
}
</script>

<style>
.color-00, .color-sk { background-color: #6977F2!important; }
.color-en { background-color: #F9630F!important; }
.color-cs { background-color: #FBEA58!important; }
.color-fr { background-color: #C669F2!important; }
.color-ru { background-color: #77889E!important; }
.color-hu { background-color: #62C8F4!important; }

.color1 { background-color: #A9EE8A; }
.color2 { background-color: #4267B2; }
.color3 { background-color: #F6894C; }
.color4 { background-color: #62C8F4; }
.color5 { background-color: #C669F2; }
.color6 { background-color: #FBEA58; }
.color7 { background-color: #77889E; }
</style>
