<template>
    <ui-sidebar title="Geography">
        <template v-slot:actions>
            <a href="#" @click.prevent="openEditLayerModal" class="text-gerulata-green-100 hover:text-gerulata-green-300" v-if="can('sentinel:manage-geo-features')">
                <ui-icon name="plus"></ui-icon> New Group
            </a>
            <a href="#" @click.prevent="openImportModal" class="text-gerulata-green-100 hover:text-gerulata-green-300" v-if="can('sentinel:manage-geo-features')">
                <ui-icon name="upload"></ui-icon> Import
            </a>
        </template>

        <div class="flex flex-col h-full">
            <div class="px-5 py-3">
                <div class="relative">
                    <div class="absolute top-0 left-3 h-9 flex items-center pointer-events-none z-10">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>
                    <ui-input placeholder="Search..." v-model="search" class="px-8"></ui-input>
                </div>
            </div>

            <ui-async :guards="! isLoading" class="divide-y divide-gray-600 px-2">
                <template v-for="layer in layers" :key="layer.id">
                    <div class="flex items-center space-x-3 leading-tight py-3 px-3 hover:bg-gray-600 rounded">
                        <div @click="toggleGeoLayer(layer.id)" v-if="layer.type == 'custom'">
                            <input type="checkbox" class="h-4 w-4 text-gerulata-green-700 border-gray-600 bg-gray-800 pointer-events-none" :checked="isGeoLayerShown(layer.id)">
                        </div>
                        <a @click.prevent="toggleLayer(layer)" href="#" class="flex-1 flex items-center space-x-1 cursor-pointer">
                            <div class="font-semibold text-gray-200">
                                {{layer.name}}
                            </div>
                            <ui-icon :name="isLayerOpen(layer) ? 'chevron-down' : 'chevron-right'" class="text-xl"></ui-icon>
                        </a>
                        <div class="flex items-center space-x-3 text-gray-300">
                            <ui-icon name="filter" class="text-gray-400" v-tooltip="'Applied as geo-fence filter.'" v-if="isGeoLayerFiltered(layer.id)"></ui-icon>
                            <ui-contextual-menu size="sm" plain>
                                <template v-if="can('sentinel:manage-geo-features')">
                                    <ui-dropdown-link icon="plus" submenu>
                                        New Geography
                                        <template v-slot:submenu>
                                            <ui-dropdown-link @click="createPolygon(layer)">Polygon</ui-dropdown-link>
                                            <ui-dropdown-link @click="createCircle(layer)">Radius</ui-dropdown-link>
                                            <ui-dropdown-link @click="createPoint(layer)">Point</ui-dropdown-link>
                                        </template>
                                    </ui-dropdown-link>
                                    <ui-dropdown-separator v-if="layer.type == 'custom'"></ui-dropdown-separator>
                                </template>
                                <ui-dropdown-link icon="filter" @click="showGeoLayer(layer.id, { filter: true, focus: true })" v-if="layer.type == 'custom'">Filter</ui-dropdown-link>
                                <!--<ui-dropdown-separator></ui-dropdown-separator>-->
                                <!--<ui-dropdown-link icon="droplet" submenu>-->
                                <!--    Color...-->
                                <!--    <template v-slot:submenu>-->
                                <!--        <ui-dropdown-link :selected="color.id == layer.color" :icon="true" @click="setLayerColor(layer, color.id)" v-for="color in colors" :key="color.id">-->
                                <!--            <template v-slot:icon>-->
                                <!--                <div class="w-4 flex justify-center items-center text-center">-->
                                <!--                    <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-marker-${color.id}`"></span>-->
                                <!--                </div>-->
                                <!--            </template>-->
                                <!--            {{color.name}}-->
                                <!--        </ui-dropdown-link>-->
                                <!--    </template>-->
                                <!--</ui-dropdown-link>-->
                                <!--<ui-dropdown-link icon="folder">Rename</ui-dropdown-link>-->
                                <template v-if="can('sentinel:manage-geo-features') && layer.type == 'custom'">
                                    <ui-dropdown-separator></ui-dropdown-separator>
                                    <ui-dropdown-link icon="edit" @click="openEditLayerModal(layer)">Edit</ui-dropdown-link>
                                    <ui-dropdown-separator></ui-dropdown-separator>
                                    <ui-dropdown-link icon="trash" @click="deleteGeoLayer(layer.id)">Delete</ui-dropdown-link>
                                </template>
                            </ui-contextual-menu>
                        </div>
                    </div>

                    <div v-if="isLayerOpen(layer)" class="divide-y divide-gray-600">
                        <div v-for="feature in layerFeatures(layer)" :key="feature.id" class="flex items-center space-x-3 leading-tight hover:bg-gray-600 rounded py-1.5 px-3">
                            <div @click="toggleGeoFeature(feature)">
                                <input type="checkbox" class="h-4 w-4 text-gerulata-green-700 border-gray-600 bg-gray-800 pointer-events-none" :checked="isGeoFeatureShown(feature)">
                            </div>
                            <div class="text-lg" :class="`text-marker-${feature?.color}`">
                                <ui-icon name="map-pin" v-if="feature.type == 'point'"></ui-icon>
                                <ui-icon name="grid" v-else></ui-icon>
                            </div>
                            <div class="flex-1">
                                <div class="font-lg font-semibold text-gray-300">
                                    {{feature.name}}
                                </div>
                            </div>
                            <div class="flex items-center space-x-3 text-gray-300">
                                <ui-icon name="filter" class="text-gray-400" v-tooltip="'Applied as geo-fence filter.'" v-if="isGeoFeatureFiltered(feature)"></ui-icon>
                                <ui-contextual-menu size="sm" plain>
                                    <ui-dropdown-link icon="filter" @click="showGeoFeature(feature, { filter: true, focus: true })">Filter</ui-dropdown-link>
                                    <template v-if="(layer.type == 'custom' || layer.type == 'default') && can('sentinel:manage-geo-features')">
                                        <ui-dropdown-separator></ui-dropdown-separator>
                                        <ui-dropdown-link icon="droplet" submenu>
                                            Color...
                                            <template v-slot:submenu>
                                                <ui-dropdown-link :selected="color.id == feature.color" :icon="true" @click="updateGeoFeatureColor(feature, color.id)" v-for="color in colors" :key="color.id">
                                                    <template v-slot:icon>
                                                        <div class="w-4 flex justify-center items-center text-center">
                                                            <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-marker-${color.id}`"></span>
                                                        </div>
                                                    </template>
                                                    {{color.name}}
                                                </ui-dropdown-link>
                                            </template>
                                        </ui-dropdown-link>
                                        <!--<ui-dropdown-link icon="folder">Layer...</ui-dropdown-link>-->
                                        <ui-dropdown-separator></ui-dropdown-separator>
                                        <ui-dropdown-link icon="edit" @click="openEditFeatureModal(feature)">Edit</ui-dropdown-link>
                                        <ui-dropdown-separator></ui-dropdown-separator>
                                        <ui-dropdown-link icon="trash" @click="deleteGeoFeature(feature)">Delete</ui-dropdown-link>
                                    </template>
                                </ui-contextual-menu>
                            </div>
                        </div>

                        <div class="py-4">
                            <ui-infinite-loading @infinite="loadMoreFeatures(layer, $event)" :identifier="layer.id"></ui-infinite-loading>

                            <div class="text-sm text-gray-300 text-center" v-if="! layerFeatures(layer).length && ! isLoadingLayer[layer.id]">
                                Empty group.
                            </div>
                        </div>
                    </div>
                </template>

                <div class="flex flex-col h-full items-center justify-center text-gray-300" v-if="! layers.length">
                    <ui-icon name="map" class="text-3xl"></ui-icon>
                    <p class="mt-2">You will find your geographies here.</p>
                </div>
            </ui-async>
        </div>
    </ui-sidebar>
</template>

<script>
import UiSidebar from '@/components/ui/sidebar'

import useOverviewStore from '@/stores/overview/overview' 
import useGeoFeaturesStore from '@/stores/me/geo-features'
import useGeoFeatureEditModalStore from '@/stores/overview/modals/geo-feature-edit'
import useGeofeatureImportModalStore from '@/stores/overview/modals/geofeature-import'
import useGeoLayerEditModalStore from '@/stores/overview/modals/geo-layer-edit'

import { textSearch } from '@/helpers'

import { mapActions, mapState } from 'pinia'

export default {
    components: { UiSidebar },
    
    data: () => ({
        search: ''
    }),

    computed: {
        ...mapState(useGeoFeaturesStore, [ 'colors', 'isLoading', 'isLoadingLayer', 'layers' ]),
        ...mapState(useGeoFeaturesStore, {
            layers(store) {
                let layers = [ ...store.layers.filter(l => l.type == 'default'), ...store.layers.filter(l => l.type != 'default') ]

                return this.search ? textSearch(this.search, layers, p => p.name) : layers
            }
        })
    },

    methods: {
        ...mapActions(useOverviewStore, [
            'isGeoFeatureFiltered', 'isGeoLayerFiltered', 'isGeoLayerShown', 'isGeoFeatureShown', 'toggleGeoFeature',
            'updateGeoFeatureColor', 'createPolygon', 'createCircle', 'createPoint', 'toggleGeoLayer',
            'deleteGeoLayer', 'deleteGeoFeature', 'showGeoLayer', 'showGeoFeature'
        ]),
        ...mapActions(useGeoFeaturesStore, [
            'isLayerOpen', 'layerFeatures', 'loadMoreFeatures', 'toggleLayer'
        ]),
        ...mapActions(useGeoFeatureEditModalStore, {
            openEditFeatureModal: 'open'
        }),
        ...mapActions(useGeofeatureImportModalStore, {
            openImportModal: 'open'
        }),
        ...mapActions(useGeoLayerEditModalStore, {
            openEditLayerModal: 'open'
        })
    }
}
</script>
