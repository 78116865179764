<template>
    <div class="py-4 px-5 space-y-4">
        <ui-async :guards="reports.data">
            <content-list :reports="reports?.data"></content-list>

<!--            TODO is this infinite loading needed?  -->
<!--            <ui-infinite-loading @infinite="loadMoreReports($event)"></ui-infinite-loading>-->
        </ui-async>
    </div>
</template>

<script>
import ContentList from '@/components/overview/details/partials/content-list'

import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    components: {
        ContentList
    },

    computed: {
        ...mapState(useDetailsStore, [ 'reports', 'loadMoreReports' ])
    }
}
</script>