<template>
    <a :href="channel.link" target="_blank" rel="noreferrer" v-for="channel in channels" :key="channel.id" class="flex items-start space-x-5 leading-tight cursor-pointer mb-3">
        <div>
            <ui-avatar :item="channel" class="block w-14 h-14 rounded-full bg-gray-400 object-contain"></ui-avatar>
        </div>

        <div class="flex-1 mt-2.5">
            <div class="float-right text-sm text-gray-300 ml-1">
                High Credibility
            </div>

            <div class="font-semibold text-gray-100">{{ channel.name }}</div>

            <div v-if="channel.type" class="flex items-center space-x-1 text-sm text-gray-300 float-left">
                <span class="capitalize">{{ platforms[channel.type]?.label || channel.type }}</span>
            </div>
        </div>
    </a>
</template>

<script>
import useDetailsStore from '@/stores/overview/details'

import { mapState } from 'pinia'

export default {
    props: [ 'channels' ],

    computed: {
        ...mapState(useDetailsStore, [ 'platforms' ])
    }
}
</script>
