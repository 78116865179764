<template>
    <search-filter name="Sources" :disabled="true" :ids="['sources', 'source-count', 'source-type']" :filters="filters" @opened="reset" :keep-open="keepOpen">
        <template v-slot:applied>
            <ui-icon name="navigation.targets" class="mr-1"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} sources
            </span>
            <span class="font-medium max-w-sm truncate" v-else-if="value.length == 1">
                {{value.length ? value[0].name : value.name}}
            </span>
            <span class="font-medium max-w-sm truncate" v-else-if="typesValue.length">
                {{typesValue.length}} types
            </span>
            <span class="font-medium max-w-sm truncate" v-else>
                Has multiple sources
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64 max-h-80 overflow-y-auto">
                <ui-dropdown-link :selected="onlyMultipleSources" @click="toggleOnlyMultipleSources" keep-open>
                    Has multiple sources
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link :selected="false" submenu submenu-width="w-48" keep-open>
                    Credibility...

                    <template v-slot:submenu>
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Credibility
                        </div>

                        <p class="text-xs px-4 pb-2 text-gray-400">
                            Exclude reports with lower than selected credibility.
                        </p>

                        <ui-dropdown-link :selected="false">High</ui-dropdown-link>
                        <ui-dropdown-link :selected="false">Medium</ui-dropdown-link>
                        <ui-dropdown-link :selected="true">Low</ui-dropdown-link>
                    </template>
                </ui-dropdown-link>
                <ui-dropdown-link :selected="selectedTypes.length" submenu submenu-width="w-60" keep-open>
                    Platform...

                    <template v-slot:submenu>
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Platform
                        </div>

                        <ui-dropdown-link v-for="type in types" :key="type.id" @click="toggleType(type)" :icon="type?.icon" checkbox :selected="selectedTypes.includes(type.id)">{{type.name}}</ui-dropdown-link>
                    </template>
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link :selected="selectedSourcesToInclude.length" submenu submenu-width="w-60" keep-open>
                    Only reports by...

                    <template v-slot:submenu>
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Only reports by
                        </div>

                        <p class="text-xs px-4 pb-2 text-gray-400">
                            Show only events reported by at least one of these sources.
                        </p>

                        <ui-dropdown-link @click="deselectSource(target)" keep-open icon="none" :key="`${source.family}-${source.id}`" v-for="source in selectedSourcesToInclude">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <ui-avatar type="target-list" :item="source" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                </div>
                            </template>
                            <template v-slot:default>
                                <div class="flex items-center group">
                                    {{source.name}}
                                    <ui-icon name="trash" class="text-gray-300 hidden group-hover:block ml-auto"></ui-icon>
                                </div>
                            </template>
                        </ui-dropdown-link>

                        <ui-dropdown-link icon="plus-circle" @click="selectSourceToInclude" keep-open>
                            Add Source...
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>

                <ui-dropdown-link :selected="selectedSourcesToExclude.length" submenu submenu-width="w-60" keep-open>
                    Exclude reports by...

                    <template v-slot:submenu>
                        <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                            Exclude reports by
                        </div>

                        <p class="text-xs px-4 pb-2 text-gray-400">
                            Show only events with reports by at least one source different than following.
                        </p>

                        <div class="max-h-80 overflow-y-auto">
                            <ui-dropdown-link @click="deselectSource(source)" keep-open icon="none" :key="`${source.family}-${source.id}`" v-for="source in selectedSourcesToExclude">
                                <template v-slot:icon>
                                    <div class="w-4 flex justify-center items-center text-center">
                                        <ui-avatar type="target-list" :item="source" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                    </div>
                                </template>
                                <template v-slot:default>
                                    <div class="flex items-center group">
                                        {{source.name}}
                                        <ui-icon name="trash" class="text-gray-300 hidden group-hover:block ml-auto"></ui-icon>
                                    </div>
                                </template>
                            </ui-dropdown-link>
                        </div>

                        <ui-dropdown-link icon="plus-circle" @click="selectSourceToExclude" keep-open>
                            Add Source...
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center text-gray-300" @click="filters.remove(['sources', 'source-count', 'source-type']); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="green" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMySourceListsStore from '@/stores/me/target-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers'

export default {
    components: { SearchFilter },

    props: [ 'filters' ],
    
    data: () => ({
        selectedSources: [],
        onlyMultipleSources: false,
        selectedTypes: [],

        types: [
            { id: 'facebook-page', name: 'Facebook Pages', icon: 'badges.target-facebook-page' },
            { id: 'facebook-user', name: 'Facebook Users', icon: 'badges.target-facebook-user' },
            { id: 'instagram-profile', name: 'Instagram Profile', icon: 'badges.target-instagram-profile' },
            { id: 'telegram-channel', name: 'Telegram Channels', icon: 'badges.target-telegram-channel' },
            { id: 'telegram-user', name: 'Telegram Users', icon: 'badges.target-telegram-user' },
            { id: 'tiktok-user', name: 'TikTok Users', icon: 'badges.target-tiktok-user' },
            { id: 'twitter-user', name: 'Twitter Users', icon: 'badges.target-twitter-user' },
            { id: 'vkontakte-community', name: 'VKontakte Communities', icon: 'badges.target-vkontakte-community' },
            { id: 'vkontakte-user', name: 'VKontakte Users', icon: 'badges.target-vkontakte-user' },
            { id: 'web-feed', name: 'Web Feeds', icon: 'badges.target-web-feed' },
            { id: 'web-site', name: 'Web Sites', icon: 'badges.target-web-site' },
            { id: 'youtube-channel', name: 'Youtube Channels', icon: 'badges.target-youtube-channel' }
        ],

        keepOpen: false
    }),

    computed: {
        value() {
            return this.filters.value('sources') || []
        },

        selectedSourcesToInclude() {
            return sortAlphabetically(this.selectedSources.filter(val => ! val.exclude))
        },

        selectedSourcesToExclude() {
            return sortAlphabetically(this.selectedSources.filter(val => val.exclude))
        },

        typesValue() {
            return this.filters.value('source-type') || []
        }
    },

    methods: {
        selectSourceToInclude() {
            this.selectSource()
        },

        selectSourceToExclude() {
            this.selectSource(true)
        },

        selectSource(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'sources', 'targetLists' ],
                limit: 50,
                onSelect: result => {
                    this.selectedSources.push({
                        id: result.id[0] == 'G' || result.id[0] == 'S' ? result.id.substring(1) : result.id,
                        type: result.resultType == 'target-list' ? 'list' : (result.id[0] == 'G' ? 'group' : 'source'),
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                },
                initialResults: [
                    ...useMySourceListsStore().items.filter(item => item.favorite || item.recent).map(item => ({
                        id: item.id,
                        resultType: 'target-list',
                        title: item.name
                    })),
                    ...useMyBookmarksStore().items.map(item => ({
                        id: item.family == 'groups' ? `G${item.id}` : `S${item.id}`,
                        monitorId: item.id,
                        resultType: 'target',
                        type: item.type,
                        title: item.title,
                        image: item.image,
                        flags: [ item.monitored ? 'monitored' : null, item.details.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    }))
                ]
            })
        },

        deselectSource(source) {
            this.selectedSources = this.selectedSources.filter(f => f.id != source.id || f.type != source.type)
        },

        toggleOnlyMultipleSources() {
            this.onlyMultipleSources = ! this.onlyMultipleSources
        },

        toggleType(type) {
            this.selectedTypes.includes(type.id) ? this.selectedTypes = this.selectedTypes.filter(t => t != type.id) : this.selectedTypes.push(type.id)
        },

        reset() {
            this.selectedSources = (this.filters.value('sources') || []).map(mention => ({
                ...mention
            }))
            this.onlyMultipleSources = !! this.filters.value('source-count')
            this.selectedTypes = this.filters.value('source-type') || []
        },

        apply() {
            if (this.selectedSources.length) {
                this.filters.set('sources', this.selectedSources.map(mention => ({ ...mention })))
            } else {
                this.filters.remove('sources')
            }

            this.onlyMultipleSources ? this.filters.set('source-count', { gte: 2 }) : this.filters.remove('source-count')
            this.selectedTypes.length ? this.filters.set('source-type', this.selectedTypes) : this.filters.remove('source-type')
        }
    }
}
</script>
