<template>
    <ui-dropdown align="right" z-index="50" width="w-56" @click.stop>
        <template v-slot:trigger>
            <a href="#" class="flex items-center justify-center focus:outline-none transition w-6 h-6 text-sm rounded-full bg-gray-600 hover:bg-gray-500 text-gray-300 hover:text-gray-200">
                <ui-icon name="settings"></ui-icon>
            </a>
        </template>

        <template v-slot:content="{ close }">
            <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                Search In
            </div>

            <ui-dropdown-link icon="type" :selected="! hasSearchType('no-title')" @click="toggleSearchType('no-title')" keep-open>
                Event Title
            </ui-dropdown-link>
            <ui-dropdown-link icon="align-left" :selected="! hasSearchType('no-body')" @click="toggleSearchType('no-body')" keep-open>
                Event Summary
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>

            <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium text-gray-300">
                Search Type
            </div>

            <ui-dropdown-link :selected="searchType == 'language'" @click="setSearchType('language')" keep-open>
                Language Search
            </ui-dropdown-link>
            <ui-dropdown-link :selected="searchType == 'exact'" @click="setSearchType('exact')" keep-open>
                Exact Search
            </ui-dropdown-link>

            <template v-if="searchType == 'exact'">
                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-dropdown-link :selected="exactSearchType == 'case-sensitive'" @click="store.applySearchLanguage('exact-cs')" keep-open>
                    Case-Sensitive
                </ui-dropdown-link>
                <ui-dropdown-link :selected="exactSearchType == 'case-insensitive'" @click="store.applySearchLanguage('exact-ci')" keep-open>
                    Case-Insensitive
                </ui-dropdown-link>
            </template>
        </template>
    </ui-dropdown>
</template>

<script>
import languageOptions from '@/stores/reusable/language-filter-options'

import { textSearch } from '@/helpers'

export default {
    data: () => ({
        search: ''
    }),

    computed: {
        options() {
            return textSearch(this.search, languageOptions.slice(0, -2), o => o.name || true)
        },

        searchType() {
            return [ 'exact-cs', 'exact-ci' ].includes(this.store.search.language) ? 'exact' : 'language'
        },

        exactSearchType() {
            return this.store.search.language == 'exact-cs' ? 'case-sensitive' : 'case-insensitive'
        }
    },

    methods: {
        hasSearchType(type) {
            return this.store.search.types.includes(type)
        },

        toggleSearchType(type) {
            this.store.toggleSearchType(type)
        },

        setSearchType(type) {
            if (type == this.searchType) return

            this.store.applySearchLanguage(type == 'language' ? this.$my.preferredLanguage : 'exact-cs')
        }
    }
}
</script>
