<template>
    <div class="py-4 px-5 space-y-2">
        {{ event.description }}
    </div>

    <div class="py-1 px-5">
        <ul class="list-disc pl-6">
            <li v-for="key_point in event.key_points" :key="key_point">{{ key_point }}</li>
        </ul>
    </div>

    <div class="py-4 px-5 space-y-2">
        <div v-if="event.location.address">
            <div class="text-sm text-gray-300 w-28 inline-block">Location</div>
            {{ event.location.address }}
        </div>

        <div v-if="event.location.country">
            <div class="text-sm text-gray-300 w-28 inline-block">Country</div>
            <ui-icon :name="country.icon" class="mr-1" v-if="country?.icon"></ui-icon>
            {{ event.location.country }}
        </div>

        <div v-if="event.category">
            <div class="text-sm text-gray-300 w-28 inline-block">Category</div>
            <div class="inline-flex items-center font-medium inline-block rounded">
                <div class="w-3 h-3 rounded-full inline-block mr-2" :style="{ backgroundColor: category?.color }"></div>
                <span class="capitalize">{{ category?.name }}</span>

                <span v-if="subcategories.length" v-for="subcategory in subcategories" :key="subcategory" class="capitalize">
                    &nbsp;→ {{ subcategory }}
                </span>
            </div>
        </div>

        <div v-if="event.casualties">
            <div class="text-sm text-gray-300 w-28 inline-block">Casualties</div>
            {{ event.casualties }}
        </div>

        <div v-if="event.startedAt">
            <div class="text-sm text-gray-300 w-28 inline-block">Event Date</div>
            {{ $dateTime(event.startedAt) }}
        </div>

        <div v-if="event.createdAt">
            <div class="text-sm text-gray-300 w-28 inline-block">First reported At</div>
            {{ $dateTime(event.createdAt) }}
        </div>

        <div v-if="event.endedAt">
            <div class="text-sm text-gray-300 w-28 inline-block">
                Finishes At
            </div>
            {{ $dateTime(event.endedAt) }}
        </div>

        <div v-if="event.topChannelReliability">
            <div class="text-sm text-gray-300 w-28 inline-block">
                Veracity
            </div>
            {{ event.topChannelReliability }}{{ event.credibility }}
        </div>

        <div v-if="event.tags?.length" class="flex items-top">
            <div class="text-sm text-gray-300 w-28 inline-block">Tags</div>
            <div class="flex flex-wrap space-x-3">
                <div v-for="tag in event.tags" :key="tag.id" class="inline-flex items-center bg-gray-600 font-medium inline-block px-2 rounded">
                    <div class="w-3 h-3 rounded-full inline-block mr-2" :class="`bg-${tag?.color}`"></div>
                    <span>{{tag.name}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="py-4 px-5 space-y-4 border-t border-gray-500 w-full">
        <h3 class="font-semibold">Timeline</h3>
        <timeline-list />
    </div>
</template>

<script>
import TimelineList from '@/components/overview/details/partials/timeline-list'
import useDetailsStore from '@/stores/overview/details'
import useOverviewStore from '@/stores/overview/overview'
import useEventCategoriesStore from "@/stores/me/event-categories"

import { mapActions, mapState, mapWritableState } from 'pinia'
import CountryOptions from "@/stores/me/countries"

export default {

    components: { TimelineList },

    computed: {
        ...mapState(useDetailsStore, [ 'event' ]),
        ...mapWritableState(useDetailsStore, [ 'shownTab' ]),

        category() { return useEventCategoriesStore().find(this.event.category) },

        subcategories() {
            if (this.event.full_category) {
                const subcategories = this.event.full_category.split('.')

                if (subcategories.length > 1) {
                    subcategories.shift()
                    return subcategories.map(s => s.replaceAll('_', ' '))
                }
            }

            return []
        },

        country() { return CountryOptions.find(c => c.name === this.event.location.country) }

    },

    methods: {
        ...mapActions(useOverviewStore, [ 'hideSidebar' ])
    }
}
</script>